
.may-ninth {
  img {
    max-width: 330px;
  }
}
@media (max-width: 768px){
  .may-ninth {
    img {
      max-width: 100%;
    }
  }
}
